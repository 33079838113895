<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
                <b-button variant="primary" :to="{ name: ($route.meta.module === 'customer' ? 'CustomerNew' : 'SupplierNew') }">
                    <font-awesome-icon icon="plus"></font-awesome-icon> Nuevo
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Mostrar" append="registros">
                    <b-form-select v-model="businessEntities.itemsPerPage" id="perPageSelect" size="sm" :options="businessEntities.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
        </b-row>
        <b-table :items="loadData" :fields="businessEntities.fields" :filter="businessEntities.filter" :current-page="businessEntities.currentPage"
                 :per-page="businessEntities.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered hover small show-empty :responsive="true" :sort-by="businessEntities.sortBy" :sort-desc="businessEntities.sortDesc"
                 ref="businessEntitiesTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in businessEntities.fields" :key="field.key"
                          :class="businessEntities.filters[index] && businessEntities.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="businessEntities.filters[index]">
                            <b-col v-if="businessEntities.filters[index].inputType === 'Select'">
                                <b-select v-model="businessEntities.filters[index].value">
                                    <template v-if="'selectOptions' in businessEntities.filters[index]">
                                        <b-select-option v-for="option in businessEntities.filters[index].selectOptions" :value="option.value" :key="option.value">
                                            {{ option.text }}
                                        </b-select-option>
                                    </template>
                                    <template v-else>
                                        <b-select-option value="">Todos</b-select-option>
                                        <b-select-option value="TRUE">Activo</b-select-option>
                                        <b-select-option value="FALSE">Inactivo</b-select-option>
                                    </template>
                                </b-select>
                            </b-col>
                            <b-col v-else-if="businessEntities.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="businessEntities.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(enabled)="row">
                <b-badge variant="primary" v-if="row.item.enabled" class="p-1">Activo</b-badge>
                <b-badge variant="warning" v-else>Inactivo</b-badge>
            </template>

            <template #cell(type)="row">
                <template v-if="row.item.type === 'FISICA'">Persona física</template>
                <template v-else>Persona moral</template>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: ($route.meta.module === 'customer' ? 'CustomerShow' : 'SupplierShow'), params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Detalle" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" :to="{ name: ($route.meta.module === 'customer' ? 'CustomerEdit' : 'SupplierEdit'), params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Modificar" variant="outline-primary">
                        <font-awesome-icon icon="edit"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" v-b-tooltip.hover :title="row.item.enabled ? 'Desactivar' : 'Activar'"
                              variant="outline-primary" @click="changeStatus(row.item)">
                        <font-awesome-icon :icon="row.item.enabled ? 'toggle-off' : 'toggle-on'"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Mostrando {{ start }} a {{ end }} de {{ businessEntities.totalRows }} registro<template v-if="businessEntities.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="businessEntities.currentPage" :total-rows="businessEntities.totalRows"
                                      :per-page="businessEntities.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { businessEntityListUrl, businessEntityEnableUrl } from '@routes';
import * as constants from '@constants';
import tableStateMixin from '../../mixins/tableState';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            businessEntities: {
                fields: [
                    { key: 'customerName', label: 'Nombre/Razón social', sortable: true },
                    { key: 'rfc', label: 'RFC', sortable: true },
                    { key: 'type', label: 'Tipo', sortable: true },
                    { key: 'contact', label: 'Contacto', sortable: true },
                    { key: 'phone1', label: 'Teléfono 1', sortable: true },
                    { key: 'email1', label: 'Email 1', sortable: true },
                    { key: 'enabled', label: 'Estatus', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Acciones', class: 'text-center' }
                ],
                filters: [
                    { column: 'customerName', type: 'STRING', value: '', format: '' },
                    { column: 'c.rfc', type: 'STRING', value: '', format: '' },
                    { column: 'c.type', type: 'STRING', value: '', format: '', inputType: 'Select',
                        selectOptions: [{ value: '', text: 'Todos' }, { value: 'FISICA', text: 'Persona física' }, { value: 'MORAL', text: 'Persona moral' }]
                    },
                    { column: 'contact', type: 'STRING', value: '', format: '' },
                    { column: 'c.phone1', type: 'STRING', value: '', format: '' },
                    { column: 'c.email1', type: 'STRING', value: '', format: '' },
                    { column: 'c.enabled', type: 'BOOLEAN', value: '', format: '', inputType: 'Select',
                        selectOptions: [{ value: '', text: 'Todos' }, { value: 'TRUE', text: 'Activo' }, { value: 'FALSE', text: 'Inactivo' }]
                    }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'customerName',
                sortDesc: false
            },
            breadcrumb: {}
        };
    },
    created() {
        this.loadBreadcrumb(this.$route.meta.module);
        this.restoreTableState(this.businessEntities, this.tableState);
    },
    methods: {
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.businessEntities.filters.filter(filter => filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                if (this.$route.meta.module === 'customer') {
                    data.isCustomer = true;
                } else if (this.$route.meta.module === 'supplier') {
                    data.isSupplier = true;
                }

                return this.axios.post(businessEntityListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.businessEntities.totalRows = response.data.totalRows;
                        return response.data.businessEntities;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        changeStatus(businessEntity) {
            this.$swal({
                title: `¿${businessEntity.enabled ? 'Desactivar' : 'Activar'} el ${(this.$route.meta.module === 'customer' ? 'cliente' : 'proveedor')} ${businessEntity.type == 'FISICA' ? businessEntity.customerName : businessEntity.legalName}?`,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.axios.put(businessEntityEnableUrl(), {
                        businessEntityId: businessEntity.id,
                        enable: !businessEntity.enabled
                    }).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            businessEntity.enabled = !businessEntity.enabled;

                            this.$bvToast.toast(response.data.message, {
                                title: (this.$route.meta.module === 'customer' ? 'Cliente' : 'Proveedor'),
                                variant: 'success'
                            });

                            this.$refs.businessEntitiesTable.refresh();
                            this.$emit('get-setting');
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al actualizar el estatus.', {
                            title: (this.$route.meta.module === 'customer' ? 'cliente' : 'proveedor'),
                            variant: 'danger'
                        });
                    });
                }
            });
        },
        loadBreadcrumb(module) {
            if (module === 'supplier') {
                this.breadcrumb = {
                    title: 'Proveedores',
                    path: []
                };

                this.$forceUpdate();
            } else if (module === 'customer') {
                this.breadcrumb = {
                    title: 'Clientes',
                    path: []
                };
            }

            this.$emit('update-breadcrumb', this.breadcrumb);

            if (this.$refs.businessEntitiesTable) {
                this.$refs.businessEntitiesTable.refresh();
            }

            this.$emit('get-setting');
        },
        tableContextChanged(context) {
            this.businessEntities.sortBy = context.sortBy;
            this.businessEntities.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.businessEntities);
        }
    },
    watch: {
        'businessEntities.filters': {
            handler() {
                this.$refs.businessEntitiesTable.refresh();
                this.saveTableState(this.$route.name, this.businessEntities);
            },
            deep: true
        },
        $route(to) {
            this.loadBreadcrumb(to.meta.module);
        }
    },
    computed: {
        start: function() {
            return this.businessEntities.totalRows === 0 ? 0 : (this.businessEntities.currentPage - 1) * this.businessEntities.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.businessEntities.currentPage - 1) * this.businessEntities.itemsPerPage;

            return (this.businessEntities.totalRows - offset) < this.businessEntities.itemsPerPage ? this.businessEntities.totalRows : offset + this.businessEntities.itemsPerPage;
        }
    }
};
</script>

<style scoped>

</style>